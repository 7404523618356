import React from "react"
import { graphql } from 'gatsby'
import { injectIntl } from "gatsby-plugin-intl"

import Layout from "@layouts/Layout"
import Metatag from "@components/metatag/Metatag"
import BannerPage from "@components/common/banner-page/BannerPage"
import Breadcrumb from "@components/common/breadcrumb/Breadcrumb"
import { RichText } from "prismic-reactjs"

function ServicesPage({ data, location, intl }) {
  const content = data?.prismicServicespage?.data

  if (!content) return null

  return (
    <Layout>
      <Metatag href={location.href} origin={location.origin} />
      <BannerPage
        texte={intl.formatMessage({ id: "services" })}
        visual={content?.cover}
      />
      <Breadcrumb
        path={location.pathname}
        pageName={intl.formatMessage({ id: "services" })}
      />
      <div className="container">
        <div className="wysiwig services">
          <RichText render={content?.content.richText} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query servicespageQuery($gatsby_lang: String) {
    prismicServicespage(lang: { eq: $gatsby_lang }) {
      data {
        content {richText}
        cover {
          url
          alt
          thumbnails {
            mobile {
              url
            }
            tablet {
              url
            }
          }
        }
      }
    }
  }
`

export default injectIntl(ServicesPage)
